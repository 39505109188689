import { getUrlData } from "../../App.utils";

const STORAGE_NAME_KEY = "terms_agreed";

class TermsStorage {
  // unique respondent key for a specific survey
  #storageSurveyKey;

  constructor() {
    const { surveyId, respondentId } = getUrlData()
    this.#storageSurveyKey = `${surveyId}_${respondentId}`;
  }

  clear() {
    window.localStorage.removeItem(STORAGE_NAME_KEY);
  }

  set termsAgreed(agreed) {
    window.localStorage.setItem(
      STORAGE_NAME_KEY,
      JSON.stringify({
        key: this.#storageSurveyKey,
        value: agreed,
      })
    );
  }

  get termsAgreed() {
    const termsAgreedStoredData = window.localStorage.getItem(STORAGE_NAME_KEY);
    if (!termsAgreedStoredData) {
      return false;
    }

    const { key, value } = JSON.parse(termsAgreedStoredData);

    if (key !== this.#storageSurveyKey) {
      return false;
    }

    return value;
  }
}

export const termsStorage = new TermsStorage();
