import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_ROOT_URL } from "./apiRootUrl";

export const termsApi = createApi({
  reducerPath: "termsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT_URL }),
  endpoints: (builder) => ({
    sendTermsAgreed: builder.mutation({
      query: (body) => ({
        url: "/responseChoice",
        method: "POST",
        body,
      }),
    }),
    sendRespondentInfo: builder.mutation({
      query: (body) => ({
        url: "/respondentInfo",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSendTermsAgreedMutation, useSendRespondentInfoMutation } =
  termsApi;
