import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import styles from "./Chat.module.css";

import { TypingProgress } from "./TypingProgress";
import { MessageRoles, MessageTypes } from "./Chat.model";

export function ChatDialogMessage({ role, type, content }) {
  const messageClassName =
    role === MessageRoles.Respondent
      ? styles["respondent-message"]
      : styles["interviewer-message"];
  const cardContent =
    type === MessageTypes.TypingProgress ? (
      <TypingProgress />
    ) : (
      // split content by `\n` and after each content part add `<br>`; in case it is last content part do not add `<br>`
      content.split("\n").flatMap((line, i, parts) => {
        const isLastContentPart = i === parts.length - 1;
        return [line, isLastContentPart ? null : <br key={i} />];
      })
    );
  return (
    <Card
      data-test-id="chat-message"
      raised
      elevation={0}
      className={`${styles.message} ${messageClassName}`}
    >
      <CardContent>{cardContent}</CardContent>
    </Card>
  );
}
