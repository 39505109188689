import { createSlice } from "@reduxjs/toolkit";

import { termsStorage } from "./TermsPage.utils";

const initialState = { agreed: termsStorage.termsAgreed };

const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    termsAgreedSet(state, { payload }) {
      termsStorage.termsAgreed = payload;
      state.agreed = payload;
    },
    termsAgreedCleared(state) {
      termsStorage.clear();
      state.agreed = false;
    },
  },
});

export const termsAgreedSelector = (state) => state.terms.agreed;

export const { termsAgreedSet, termsAgreedCleared } = termsSlice.actions;

export default termsSlice.reducer;
