import { useEffect } from "react";
import { delay } from "lodash";

import styles from "./Chat.module.css";

import { redirectOnComplete } from "./Chat.utils";
import { RespondentInput } from "./RespondentInput";
import { useChatHistoryPolling } from "./Chat.hooks";
import { ChatDialog } from "./ChatDialog";

export function Chat() {
  const { interviewEnded } = useChatHistoryPolling();

  useEffect(() => {
    if (interviewEnded) {
      delay(redirectOnComplete, 2000);
    }
  }, [interviewEnded]);

  return (
    <main className={styles.chat}>
      <section className={styles["chat-dialog-wrapper"]}>
        <ChatDialog />
      </section>
      <footer className={styles.footer}>
        <div className={styles["user-input-wrapper"]}>
          <RespondentInput />
        </div>
      </footer>
    </main>
  );
}
