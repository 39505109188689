import { experimental_extendTheme as extendTheme } from "@mui/material/styles";

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#416171",
        },
        secondary: {
          main: "#0BAC07",
        },
      },
      typography: {
        fontFamily: [
          "Proxima Nova",
          "Helvetica Neue",
          "Arial",
          "Helvetica",
          "sans-serif",
        ].join(","),
        h1: {
          fontSize: "4rem",
        },
        h2: {
          fontSize: "3.2rem",
        },
        h4: {
          fontSize: "2.4rem",
        },
        h5: {
          fontSize: "2rem",
        },
        subtitle1: {
          fontSize: "1.8rem",
        },
        subtitle2: {
          fontSize: "1.6rem",
        },
        body1: {
          fontSize: "1.4rem",
        },
        body2: {
          fontSize: "1.2rem",
        },
      },
    },
  },
});
