import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { termsAgreedSet } from "./TermsPage.slice";
import { getUrlData } from "../../App.utils";
import {
  useSendTermsAgreedMutation,
  useSendRespondentInfoMutation,
} from "../../app/terms.api";

const DRAFT_PRIVACY_QUESTION_ID = "85f91009-66d0-4700-880a-753ee95238b6";
const renderingDateTime = new Date().toISOString();

export const useBeginChat = () => {
  const dispatch = useDispatch();
  const [sendTermsAgreedMutation] = useSendTermsAgreedMutation();
  const [sendRespondentInfoMutation] = useSendRespondentInfoMutation();

  return () => {
    const {
      surveyId,
      respondentId,
      preview,
      ps,
      mid,
      g,
      yob,
      age,
      pid,
      pc,
      panid,
      on,
    } = getUrlData();
    const isPreview = preview === "true";
    const submissionDatetime = new Date().toISOString();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcOffset = dayjs().format("Z");
    const respondentMetaData = {
      surveyId,
      respondentId,
      panelSupplierCode: ps,
      isPreview,
      submissionDatetime,
      timeZone,
      utcOffset,
    };

    dispatch(termsAgreedSet(true));

    sendTermsAgreedMutation({
      ...respondentMetaData,
      enteringDatetime: renderingDateTime,
      questionId: DRAFT_PRIVACY_QUESTION_ID,
      entryPosition: -1,
      renderedEntryPosition: -1,
      previousEntryPosition: -1,
      contextPosition: -1,
      renderedContextPosition: -1,
      entryType: "TermsEntryType",
      responseChoices: [],
    });

    sendRespondentInfoMutation({
      ...respondentMetaData,
      memberId: mid,
      gender: g,
      yearOfBirth: yob ? Number.parseInt(yob, 10) : null,
      age: age ? Number.parseInt(age, 10) : null,
      panelistId: pid,
      postCode: pc,
      panelId: panid,
      orderNumber: on ? Number.parseInt(on, 10) : null,
      metaData: null,
    });
  };
};
