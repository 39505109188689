import { useRef } from "react";

import { useGetChatHistoryQuery } from "../../app/chat.api";

const CHAT_HISTORY_POLLING_INTERVAL = 1000;

export const useChatHistoryPolling = () => {
  const interviewEndedRef = useRef(false);
  const {
    data: { chatMessages = [], interviewEnded } = {
      chatMessages: [],
      interviewEnded: false,
    },
  } = useGetChatHistoryQuery(undefined, {
    pollingInterval: interviewEndedRef.current
      ? 0
      : CHAT_HISTORY_POLLING_INTERVAL,
  });

  interviewEndedRef.current = interviewEnded;

  return { chatMessages, interviewEnded };
};
