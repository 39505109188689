export const MessageTypes = {
  TypingProgress: "TypingProgress",
  Text: "Text",
  InterviewEndedL: "InterviewEnded",
};

export const MessageRoles = {
  Interviewer: "Interviewer",
  Respondent: "Respondent",
};
