import { getUrlData } from "../../App.utils";
import {
  CINT_REDIRECT_URL,
  PROLIFIC_REDIRECT_URL,
  PROLIFIC_COMPLETE_CODE,
} from "../../utils/constants";

export const redirectOnComplete = () => {
  const { respondentId, preview, ps } = getUrlData();

  if (preview !== "true") {
    if (ps === "cint") {
      window.location.href = `${CINT_REDIRECT_URL}/${respondentId}`;
    } else if (ps === "prolific") {
      window.location.href = `${PROLIFIC_REDIRECT_URL}?cc=${PROLIFIC_COMPLETE_CODE}`;
    }
  }
};
