import { rest } from "msw";

import { API_ROOT_URL } from "../app/apiRootUrl";
import data from "./data.json";

export const handlers = [
  rest.get(`${API_ROOT_URL}/chat-history`, (req, res, ctx) => {
    return res(ctx.json(data));
  }),

  rest.get(`${API_ROOT_URL}/chat-message`, (req, res, ctx) => {
    const index = new URLSearchParams(req.url.search).get("index");
    return res(ctx.json(data[index]));
  }),

  rest.post(`${API_ROOT_URL}/chat-message`, (req, res, ctx) => {
    data.push(req.body.data)
    return res(ctx.json(req.body));
  }),
];
