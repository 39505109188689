import { useEffect, useRef } from "react";

import styles from "./Chat.module.css";

import { useChatHistoryPolling } from "./Chat.hooks";
import { ChatDialogMessage } from "./ChatDialogMessage";
import { MessageRoles } from "./Chat.model";

const IconFd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor">
      <path d="M9.601 9.603H6.396V16h6.397v-3.205H9.601z" />
      <path d="M3.205 6.397V3.205H0V16h3.205V9.602h3.192V6.397zM9.602 0H3.205v3.205h6.397zM12.795 0v6.397H9.603v3.206h3.192v3.192H16V0z" />
    </g>
  </svg>
);

export function ChatDialog() {
  const chatDialogRef = useRef();
  const { chatMessages } = useChatHistoryPolling();

  useEffect(() => {
    chatDialogRef.current.scrollBy({ top: chatDialogRef.current.scrollHeight });
  }, [chatMessages]);

  return (
    <div className={styles["chat-dialog"]} ref={chatDialogRef}>
      <ul>
        {chatMessages.map(({ role, type, content }, index) => {
          const isInterviewerMessage = role !== MessageRoles.Respondent;
          const messageWrapperClassName = isInterviewerMessage
            ? styles["interviewer-message-wrapper"]
            : styles["respondent-message-wrapper"];
          return (
            <div
              key={index}
              className={`${styles["message-wrapper"]} ${messageWrapperClassName}`}
            >
              {isInterviewerMessage && (
                <div className={styles["interviewer-message-avatar"]}>
                  <IconFd />
                </div>
              )}
              <ChatDialogMessage role={role} type={type} content={content} />
            </div>
          );
        })}
      </ul>
    </div>
  );
}
