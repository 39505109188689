import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";

import styles from "./TermsPage.module.css";
import { termsAgreedSelector, termsAgreedCleared } from "./TermsPage.slice";
import { TermsInfo } from "./TermsInfo";
import { useBeginChat } from "./TermsPage.hooks";

export const TermsPage = () => {
  const termsAgreed = useSelector(termsAgreedSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(termsAgreedCleared());
  }, [dispatch]);
  const beginChat = useBeginChat();

  const [termsAgreedChecked, setTermsAgreedChecked] = useState(termsAgreed);

  const termsCheckbox = (
    <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            checked={termsAgreedChecked}
            onChange={() => setTermsAgreedChecked(!termsAgreedChecked)}
          />
        }
        label="I agree that any personal information provided in this survey can be used for the purpose(s) mentioned in the Privacy Notice"
      />
    </Grid>
  );
  const beginButton = (
    <Grid item className={styles["begin-button-wrapper"]}>
      <Button
        color="primary"
        variant="contained"
        disabled={!termsAgreedChecked}
        onClick={beginChat}
        fullWidth
      >
        Begin
      </Button>
    </Grid>
  );
  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      className={styles.wrapper}
    >
      <Typography className={styles.title}>
        TERMS AND CONDITIONS OF USE OF{" "}
        <a href="https://focaldata.com" rel="noreferrer" target="_blank">
          FOCALDATA.COM
        </a>
      </Typography>
      {termsCheckbox}
      {beginButton}
      <div dir="ltr" className={styles.terms}>
        <TermsInfo />
      </div>
      {termsCheckbox}
      {beginButton}
    </Grid>
  );
};
