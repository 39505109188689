import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
import { last, isMatch } from "lodash";

import { getUrlData } from "../App.utils";
import { MessageRoles, MessageTypes } from "../features/Chat/Chat.model";
import { API_ROOT_URL } from "./apiRootUrl";

const Tags = {
  Messages: "Messages",
};

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT_URL }),
  tagTypes: [Tags.Messages],
  endpoints: (builder) => ({
    getChatHistory: builder.query({
      query: () => {
        const { preview, surveyId, respondentId, ps } = getUrlData();
        const queryParams = new URLSearchParams({
          surveyId,
          respondentId,
          panelSupplierCode: ps,
          preview: preview === "true",
        });
        return {
          url: `/chat-history?${queryParams}`,
        };
      },
      transformResponse: ({ data, interviewEnded }) => {
        const lastInterviewerMessage = last(data);
        const isLastMessageRespondent = isMatch(lastInterviewerMessage, {
          role: MessageRoles.Respondent,
        });

        // show interviewer typing
        if (!interviewEnded && isLastMessageRespondent) {
          const typingMessage = {
            role: MessageRoles.Interviewer,
            type: MessageTypes.TypingProgress,
            content: "",
          };
          data.push(typingMessage);
        }

        const hasInterviewEndedMessage = isMatch(lastInterviewerMessage, {
          type: MessageTypes.InterviewEnded,
        });

        // show chat ended message
        if (interviewEnded && !hasInterviewEndedMessage) {
          data.push({
            role: MessageRoles.Interviewer,
            type: MessageTypes.InterviewEnded,
            content: "That concludes the interview. Thanks for your time",
          });
        }

        return {
          chatMessages: data,
          interviewEnded,
        };
      },
      providesTags: [Tags.Messages],
    }),
    postChatMessage: builder.mutation({
      query: ({ previousFDChatMessageDataId, content }) => {
        const { surveyId, respondentId, ps } = getUrlData();
        return {
          url: "/chat-message",
          method: "POST",
          body: {
            surveyId,
            respondentId,
            panelSupplierCode: ps,
            previousFDChatMessageDataId,
            data: {
              role: MessageRoles.Respondent,
              type: MessageTypes.Text,
              content,
            },
            submissionDatetime: new Date().toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            utcOffset: dayjs().format("Z"),
          },
        };
      },
      onQueryStarted: async ({ content }, { dispatch, queryFulfilled }) => {
        const patchRespondentMessageResult = dispatch(
          chatApi.util.updateQueryData(
            "getChatHistory",
            undefined,
            (chatHistoryResult) => {
              chatHistoryResult.chatMessages?.push({
                role: MessageRoles.Respondent,
                type: MessageTypes.Text,
                content,
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchRespondentMessageResult.undo();
        }
      },
    }),
  }),
});

export const { useGetChatHistoryQuery, usePostChatMessageMutation } = chatApi;
