import { configureStore } from "@reduxjs/toolkit";
import { termsApi } from "./terms.api";
import { chatApi } from "./chat.api";
import termsReducer from "../features/TermsPage/TermsPage.slice";

export const store = configureStore({
  reducer: {
    terms: termsReducer,
    [termsApi.reducerPath]: termsApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(termsApi.middleware, chatApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});
