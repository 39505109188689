const getRespondentInfoFromPath = (path) => {
  const [surveyId = "", respondentId = ""] = path?.slice(1).split("/") ?? [];
  return { surveyId, respondentId };
};

const getRespondentInfoFromSearchParams = (searchParams) => {
  return Object.fromEntries(new URLSearchParams(searchParams));
};

export const helpers = {
  getRespondentInfoFromPath,
  getRespondentInfoFromSearchParams,
};

/**
 * Parses current URL ant returns key-value pairs with URL data
 * @example
 * non-preview URL: `https://fdchat.focaldata.com/d6d75c6c-1212-4fd2-9794-f546ca120cc1/83e5f232-48fc-438e-8b43-185566a8f5a6?on=0&type=survey&ps=cint&rid=83e5f232-48fc-438e-8b43-185566a8f5a6&mid=123456&age=74&pid=1&g=f&yob=1997&panid=9984&pc=SL37AU&collect=true&hybrid=true`
 * preview URL: `https://fdchat.focaldata.com/d6d75c6c-1212-4fd2-9794-f546ca120cc1/83e5f232-48fc-438e-8b43-185566a8f5a6?preview=true&embedded=true&ps=fd`
 * @returns {{
 *  surveyId: string
 *  respondentId: string
 *  preview: string | null
 *  embedded: string | null
 *  hybrid: string | null
 *  on: string | null
 *  type: string | null
 *  ps: string | null
 *  rid: string | null
 *  mid: string | null
 *  age: string | null
 *  pid: string | null
 *  g: string | null
 *  yob: string | null
 *  panid: string | null
 *  pc: string | null
 *  collect: string | null
 * }}
 */
export const getUrlData = () => {
  const urlData = helpers.getRespondentInfoFromPath(window.location.pathname);

  const searchParams = helpers.getRespondentInfoFromSearchParams(
    window.location.search
  );

  return new Proxy(Object.assign(urlData, searchParams), {
    get: function (object, key) {
      return object[key] || null;
    },
  });
};
