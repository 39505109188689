import { useSelector } from "react-redux";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";

import styles from "./App.module.css";
import { theme } from "./theme";

import { termsAgreedSelector } from "./features/TermsPage/TermsPage.slice";
import { InfoPanel } from "./features/InfoPanel";
import { Chat } from "./features/Chat";
import { TermsPage } from "./features/TermsPage";
import { getUrlData } from "./App.utils";

function App() {
  const termsAgreed = useSelector(termsAgreedSelector);
  const { preview, hybrid } = getUrlData();
  const isPreview = preview === "true";
  const isHybrid = hybrid === "true";
  const shouldShowTerms = !isPreview && !isHybrid && !termsAgreed;
  const chatApp = (
    <div className={styles["chat-app"]}>
      <InfoPanel />
      <Chat />
    </div>
  );
  return (
    <CssVarsProvider theme={theme}>
      {shouldShowTerms ? <TermsPage /> : chatApp}
    </CssVarsProvider>
  );
}

export default App;
